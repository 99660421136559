import { default as _403WRigwub4siMeta } from "/opt/build/repo/apps/back-office/pages/403.vue?macro=true";
import { default as callbackgm73ofIZLoMeta } from "/opt/build/repo/apps/back-office/pages/auth/callback.vue?macro=true";
import { default as confirmationAEp04yAiGJMeta } from "/opt/build/repo/apps/back-office/pages/auth/confirmation.vue?macro=true";
import { default as defaultPmi4ZoxMbZMeta } from "/opt/build/repo/apps/back-office/pages/auth/default.vue?macro=true";
import { default as recoveryN42mgWufHsMeta } from "/opt/build/repo/apps/back-office/pages/auth/recovery.vue?macro=true";
import { default as signatureCUH55DpU7DMeta } from "/opt/build/repo/apps/back-office/pages/customer/timesheets/signature.vue?macro=true";
import { default as _91id_93vf20uRZTaAMeta } from "/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index/[id].vue?macro=true";
import { default as listpcjFJgWiWhMeta } from "/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index/list.vue?macro=true";
import { default as indexaOJK03BxQjMeta } from "/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index.vue?macro=true";
import { default as _91id_939SAjjLBnIQMeta } from "/opt/build/repo/apps/back-office/pages/index/amendments/[id].vue?macro=true";
import { default as indexozgettx1R3Meta } from "/opt/build/repo/apps/back-office/pages/index/amendments/index.vue?macro=true";
import { default as indexJVGiQMFqD7Meta } from "/opt/build/repo/apps/back-office/pages/index/applications/index.vue?macro=true";
import { default as indexfRbRIBjZiUMeta } from "/opt/build/repo/apps/back-office/pages/index/calculator/index.vue?macro=true";
import { default as indexzRWufwCk8KMeta } from "/opt/build/repo/apps/back-office/pages/index/commercial-conditions/index.vue?macro=true";
import { default as _91id_930pLwEPUI17Meta } from "/opt/build/repo/apps/back-office/pages/index/companies/[id].vue?macro=true";
import { default as _91id_93sWM4DWcyVfMeta } from "/opt/build/repo/apps/back-office/pages/index/company-groups/[id].vue?macro=true";
import { default as index626hsqeZIeMeta } from "/opt/build/repo/apps/back-office/pages/index/company-insurance-requests/index.vue?macro=true";
import { default as _91id_93hnkwFUWa1EMeta } from "/opt/build/repo/apps/back-office/pages/index/contacts/[id].vue?macro=true";
import { default as indexHUBhEFUzqiMeta } from "/opt/build/repo/apps/back-office/pages/index/contacts/index.vue?macro=true";
import { default as _91id_934Ao3N8syC9Meta } from "/opt/build/repo/apps/back-office/pages/index/contracts/[id].vue?macro=true";
import { default as indexJ61n2htYMNMeta } from "/opt/build/repo/apps/back-office/pages/index/contracts/index.vue?macro=true";
import { default as index7bAnls5H2aMeta } from "/opt/build/repo/apps/back-office/pages/index/convert/index.vue?macro=true";
import { default as indexDayix5wOw8Meta } from "/opt/build/repo/apps/back-office/pages/index/cooptation/index.vue?macro=true";
import { default as action_45center98pqPTbiHRMeta } from "/opt/build/repo/apps/back-office/pages/index/dashboard/index/action-center.vue?macro=true";
import { default as statisticoPVTAxkgenMeta } from "/opt/build/repo/apps/back-office/pages/index/dashboard/index/statistic.vue?macro=true";
import { default as indexT7ZKiHLOx9Meta } from "/opt/build/repo/apps/back-office/pages/index/dashboard/index.vue?macro=true";
import { default as indexCxkKnhBJ3OMeta } from "/opt/build/repo/apps/back-office/pages/index/definitive-end-requests/index.vue?macro=true";
import { default as _91id_93VO8QxLC7e6Meta } from "/opt/build/repo/apps/back-office/pages/index/expense-claim-requests/[id].vue?macro=true";
import { default as indexksmYA2JNsbMeta } from "/opt/build/repo/apps/back-office/pages/index/expense-claim-requests/index.vue?macro=true";
import { default as indexpGvOKIfBUUMeta } from "/opt/build/repo/apps/back-office/pages/index/import-rib/index.vue?macro=true";
import { default as _91id_93feuNQBDhtkMeta } from "/opt/build/repo/apps/back-office/pages/index/independent-payment-requests/[id].vue?macro=true";
import { default as index3E2N0iCc82Meta } from "/opt/build/repo/apps/back-office/pages/index/independent-payment-requests/index.vue?macro=true";
import { default as indexFY39WYncdCMeta } from "/opt/build/repo/apps/back-office/pages/index/independents/index.vue?macro=true";
import { default as indexDjBfQXnN9UMeta } from "/opt/build/repo/apps/back-office/pages/index/index.vue?macro=true";
import { default as indexIcMhXM04FVMeta } from "/opt/build/repo/apps/back-office/pages/index/medical-centers/index.vue?macro=true";
import { default as _91id_93UdmTZ1RrUVMeta } from "/opt/build/repo/apps/back-office/pages/index/medical-checkup-requests/[id].vue?macro=true";
import { default as indexdcHOUyukpwMeta } from "/opt/build/repo/apps/back-office/pages/index/medical-checkup-requests/index.vue?macro=true";
import { default as applications8TfZUm2wQRMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id]/applications.vue?macro=true";
import { default as detailsLVjzU5Wl4qMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id]/details.vue?macro=true";
import { default as find_45talentscyn2y2fv2fMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id]/find-talents.vue?macro=true";
import { default as job_45postingAS9jaUbjZRMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id]/job-posting.vue?macro=true";
import { default as notesKTT4cj4T0yMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id]/notes.vue?macro=true";
import { default as _91id_93IxB8DgD3gfMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/[id].vue?macro=true";
import { default as indexMUvxZNyWoYMeta } from "/opt/build/repo/apps/back-office/pages/index/missions/index.vue?macro=true";
import { default as indexS57LWmobrEMeta } from "/opt/build/repo/apps/back-office/pages/index/organigram/index.vue?macro=true";
import { default as _91id_93BrzHuyTOSNMeta } from "/opt/build/repo/apps/back-office/pages/index/pay-tracking/[id].vue?macro=true";
import { default as indexA1lE1EVWdWMeta } from "/opt/build/repo/apps/back-office/pages/index/pay-tracking/index.vue?macro=true";
import { default as _91contract_key_93bCfVKMQTDPMeta } from "/opt/build/repo/apps/back-office/pages/index/planning/[contract_key].vue?macro=true";
import { default as indexgaJ4XDNF15Meta } from "/opt/build/repo/apps/back-office/pages/index/planning/index.vue?macro=true";
import { default as _91id_93lLCDrlrUUVMeta } from "/opt/build/repo/apps/back-office/pages/index/ppe-requests/[id].vue?macro=true";
import { default as indexp5SSULigZgMeta } from "/opt/build/repo/apps/back-office/pages/index/ppe-requests/index.vue?macro=true";
import { default as _91id_93fRHpQ2EkmuMeta } from "/opt/build/repo/apps/back-office/pages/index/professional-card-requests/[id].vue?macro=true";
import { default as indexqWVlLlSa2YMeta } from "/opt/build/repo/apps/back-office/pages/index/professional-card-requests/index.vue?macro=true";
import { default as indexsanVCKFr6FMeta } from "/opt/build/repo/apps/back-office/pages/index/profile/index.vue?macro=true";
import { default as _91id_93WjTlu64pOYMeta } from "/opt/build/repo/apps/back-office/pages/index/qualification-centers/[id].vue?macro=true";
import { default as indexniinVxPYQmMeta } from "/opt/build/repo/apps/back-office/pages/index/qualification-centers/index.vue?macro=true";
import { default as _91id_93ZTyl3DYjgvMeta } from "/opt/build/repo/apps/back-office/pages/index/qualification-training-requests/[id].vue?macro=true";
import { default as indexVkXM23NsxSMeta } from "/opt/build/repo/apps/back-office/pages/index/qualification-training-requests/index.vue?macro=true";
import { default as _91id_93cVgyJjRBXWMeta } from "/opt/build/repo/apps/back-office/pages/index/recruitment-office-invoice-requests/[id].vue?macro=true";
import { default as index5iZ60zeRsJMeta } from "/opt/build/repo/apps/back-office/pages/index/recruitment-office-invoice-requests/index.vue?macro=true";
import { default as indexIEEgInRIuSMeta } from "/opt/build/repo/apps/back-office/pages/index/reference-checks/index.vue?macro=true";
import { default as account968SQs7fJ6Meta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/account.vue?macro=true";
import { default as membersWgoyzjArYfMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/members.vue?macro=true";
import { default as workspacer5dASG9OJvMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index/workspace.vue?macro=true";
import { default as index5b7i6UHe7aMeta } from "/opt/build/repo/apps/back-office/pages/index/settings/index.vue?macro=true";
import { default as anonymous_45biojP2s3kvFRdMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/anonymous-bio.vue?macro=true";
import { default as anonymous_45resumeP1W4otBJqMMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/anonymous-resume.vue?macro=true";
import { default as applicationsWzTA6y7ktwMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/applications.vue?macro=true";
import { default as contractse0jorHA3MKMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/contracts.vue?macro=true";
import { default as detailse2KcveZi0OMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/details.vue?macro=true";
import { default as documentsRyJLM5JHCwMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/documents.vue?macro=true";
import { default as find_45missionsCqQM4T2CBMMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/find-missions.vue?macro=true";
import { default as new_45pageaiIEFsIwMGMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/new-page.vue?macro=true";
import { default as notesVq6tJjQ4u8Meta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/notes.vue?macro=true";
import { default as qualificationGbBRUuGTDcMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/qualification.vue?macro=true";
import { default as resumeztxsORRpBVMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/resume.vue?macro=true";
import { default as _91id_93oVcKrKMhKpMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/[id].vue?macro=true";
import { default as details86cCegsJF2Meta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/hiring-pools/[id]/details.vue?macro=true";
import { default as index7jmAjFVeaaMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/hiring-pools/index.vue?macro=true";
import { default as listKZdseRUrlFMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/list.vue?macro=true";
import { default as new_45searchTLRI14cmryMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/new-search.vue?macro=true";
import { default as push_45marketingTN6uDL3TMIMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/push-marketing.vue?macro=true";
import { default as searchnI3ozyiJduMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/search.vue?macro=true";
import { default as substitute_45benchXsHzSp2lG2Meta } from "/opt/build/repo/apps/back-office/pages/index/talents/index/substitute-bench.vue?macro=true";
import { default as indexcf5KLjba2aMeta } from "/opt/build/repo/apps/back-office/pages/index/talents/index.vue?macro=true";
import { default as indexkl9qVRRdIQMeta } from "/opt/build/repo/apps/back-office/pages/index/timesheets/index.vue?macro=true";
import { default as indexB9Dp1PFdAJMeta } from "/opt/build/repo/apps/back-office/pages/index/users/index.vue?macro=true";
import { default as _91id_93xYESk3bfsxMeta } from "/opt/build/repo/apps/back-office/pages/index/work-accident-requests/[id].vue?macro=true";
import { default as indexOQzxKf2NCDMeta } from "/opt/build/repo/apps/back-office/pages/index/work-accident-requests/index.vue?macro=true";
import { default as indexJKLANVOE6vMeta } from "/opt/build/repo/apps/back-office/pages/index.vue?macro=true";
import { default as loginEnHr1pJDIuMeta } from "/opt/build/repo/apps/back-office/pages/login.vue?macro=true";
import { default as logout7j2RmEAQVaMeta } from "/opt/build/repo/apps/back-office/pages/logout.vue?macro=true";
import { default as onboardingKgMjeqy0oIMeta } from "/opt/build/repo/apps/back-office/pages/onboarding.vue?macro=true";
import { default as accordionsWgX3zsk9CrMeta } from "/opt/build/repo/apps/back-office/pages/playground/accordions.vue?macro=true";
import { default as button_45copy0kv0iQOO2LMeta } from "/opt/build/repo/apps/back-office/pages/playground/button-copy.vue?macro=true";
import { default as comboboxKWpLesFpSeMeta } from "/opt/build/repo/apps/back-office/pages/playground/combobox.vue?macro=true";
import { default as contract_45status_45selecto6c7I7eMHPMeta } from "/opt/build/repo/apps/back-office/pages/playground/contract-status-select.vue?macro=true";
import { default as errorbzWBCKPU67Meta } from "/opt/build/repo/apps/back-office/pages/playground/error.vue?macro=true";
import { default as factoivFQnKJskhMeta } from "/opt/build/repo/apps/back-office/pages/playground/facto.vue?macro=true";
import { default as google_45mapsYURfTuulCkMeta } from "/opt/build/repo/apps/back-office/pages/playground/google-maps.vue?macro=true";
import { default as indexQFybm4BYcpMeta } from "/opt/build/repo/apps/back-office/pages/playground/index.vue?macro=true";
import { default as langchain_45streaming_45with_45structured_45outputMM0fDqzjxfMeta } from "/opt/build/repo/apps/back-office/pages/playground/langchain-streaming-with-structured-output.vue?macro=true";
import { default as langchain_45streaminggXnVEjCaRTMeta } from "/opt/build/repo/apps/back-office/pages/playground/langchain-streaming.vue?macro=true";
import { default as merge_45to_45pdftwRQPm5DdVMeta } from "/opt/build/repo/apps/back-office/pages/playground/merge-to-pdf.vue?macro=true";
import { default as messenger_45email_45dialogdpwwfL9fAPMeta } from "/opt/build/repo/apps/back-office/pages/playground/messenger-email-dialog.vue?macro=true";
import { default as monthFilterButtonrqCFNPVvkzMeta } from "/opt/build/repo/apps/back-office/pages/playground/monthFilterButton.vue?macro=true";
import { default as notepyW3SPLj90Meta } from "/opt/build/repo/apps/back-office/pages/playground/note.vue?macro=true";
import { default as pappersx9j02MLuLNMeta } from "/opt/build/repo/apps/back-office/pages/playground/pappers.vue?macro=true";
import { default as protect_45routeqO62NFpG8aMeta } from "/opt/build/repo/apps/back-office/pages/playground/protect-route.vue?macro=true";
import { default as reporting_45barSsIZZOvZ9qMeta } from "/opt/build/repo/apps/back-office/pages/playground/reporting-bar.vue?macro=true";
import { default as sms_45remindernIy913EufZMeta } from "/opt/build/repo/apps/back-office/pages/playground/sms-reminder.vue?macro=true";
import { default as smsKOrpEm0fVZMeta } from "/opt/build/repo/apps/back-office/pages/playground/sms.vue?macro=true";
import { default as supabase_45dropzoneyzh7dYGo65Meta } from "/opt/build/repo/apps/back-office/pages/playground/supabase-dropzone.vue?macro=true";
import { default as super_45comboboxsnE83AFOJUMeta } from "/opt/build/repo/apps/back-office/pages/playground/super-combobox.vue?macro=true";
import { default as switch5rtPPy2do6Meta } from "/opt/build/repo/apps/back-office/pages/playground/switch.vue?macro=true";
import { default as talent_45cardswI3k60sJvMeta } from "/opt/build/repo/apps/back-office/pages/playground/talent-card.vue?macro=true";
import { default as talent_45request_45previewUTz0og2qYbMeta } from "/opt/build/repo/apps/back-office/pages/playground/talent-request-preview.vue?macro=true";
import { default as team_45member_45select_45modalP4tYTCimWwMeta } from "/opt/build/repo/apps/back-office/pages/playground/team-member-select-modal.vue?macro=true";
import { default as timesheet_45daysAayryRG3cUMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-days.vue?macro=true";
import { default as timesheet_45status_45pillUJzMm5HYsTMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-status-pill.vue?macro=true";
import { default as timesheet_45time_45formatj1LUAY2huTMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-time-format.vue?macro=true";
import { default as timesheet_45time_45inputEHuafrzBCBMeta } from "/opt/build/repo/apps/back-office/pages/playground/timesheet-time-input.vue?macro=true";
import { default as vue_45pdf4AuYc6Y6lZMeta } from "/opt/build/repo/apps/back-office/pages/playground/vue-pdf.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    meta: _403WRigwub4siMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/403.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackgm73ofIZLoMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/callback.vue")
  },
  {
    name: "auth-confirmation",
    path: "/auth/confirmation",
    meta: confirmationAEp04yAiGJMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/confirmation.vue")
  },
  {
    name: "auth-default",
    path: "/auth/default",
    meta: defaultPmi4ZoxMbZMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/default.vue")
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryN42mgWufHsMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/auth/recovery.vue")
  },
  {
    name: "customer-timesheets-signature",
    path: "/customer/timesheets/signature",
    meta: signatureCUH55DpU7DMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/customer/timesheets/signature.vue")
  },
  {
    name: indexJKLANVOE6vMeta?.name,
    path: "/",
    meta: indexJKLANVOE6vMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index.vue"),
    children: [
  {
    name: "index-advance-payment-requests",
    path: "advance-payment-requests",
    meta: indexaOJK03BxQjMeta || {},
    redirect: "list",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index.vue"),
    children: [
  {
    name: "index-advance-payment-requests-index-id",
    path: ":id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index/[id].vue")
  },
  {
    name: "index-advance-payment-requests-index-list",
    path: "list",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/advance-payment-requests/index/list.vue")
  }
]
  },
  {
    name: "index-amendments-id",
    path: "amendments/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/amendments/[id].vue")
  },
  {
    name: "index-amendments",
    path: "amendments",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/amendments/index.vue")
  },
  {
    name: "index-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/applications/index.vue")
  },
  {
    name: "index-calculator",
    path: "calculator",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/calculator/index.vue")
  },
  {
    name: "index-commercial-conditions",
    path: "commercial-conditions",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/commercial-conditions/index.vue")
  },
  {
    name: "index-companies-id",
    path: "companies/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/companies/[id].vue")
  },
  {
    name: "index-company-groups-id",
    path: "company-groups/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/company-groups/[id].vue")
  },
  {
    name: "index-company-insurance-requests",
    path: "company-insurance-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/company-insurance-requests/index.vue")
  },
  {
    name: "index-contacts-id",
    path: "contacts/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/contacts/[id].vue")
  },
  {
    name: "index-contacts",
    path: "contacts",
    meta: indexHUBhEFUzqiMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/contacts/index.vue")
  },
  {
    name: "index-contracts-id",
    path: "contracts/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/contracts/[id].vue")
  },
  {
    name: "index-contracts",
    path: "contracts",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/contracts/index.vue")
  },
  {
    name: "index-convert",
    path: "convert",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/convert/index.vue")
  },
  {
    name: "index-cooptation",
    path: "cooptation",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/cooptation/index.vue")
  },
  {
    name: "index-dashboard",
    path: "dashboard",
    meta: indexT7ZKiHLOx9Meta || {},
    redirect: indexT7ZKiHLOx9Meta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/dashboard/index.vue"),
    children: [
  {
    name: "index-dashboard-index-action-center",
    path: "action-center",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/dashboard/index/action-center.vue")
  },
  {
    name: "index-dashboard-index-statistic",
    path: "statistic",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/dashboard/index/statistic.vue")
  }
]
  },
  {
    name: "index-definitive-end-requests",
    path: "definitive-end-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/definitive-end-requests/index.vue")
  },
  {
    name: "index-expense-claim-requests-id",
    path: "expense-claim-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/expense-claim-requests/[id].vue")
  },
  {
    name: "index-expense-claim-requests",
    path: "expense-claim-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/expense-claim-requests/index.vue")
  },
  {
    name: "index-import-rib",
    path: "import-rib",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/import-rib/index.vue")
  },
  {
    name: "index-independent-payment-requests-id",
    path: "independent-payment-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/independent-payment-requests/[id].vue")
  },
  {
    name: "index-independent-payment-requests",
    path: "independent-payment-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/independent-payment-requests/index.vue")
  },
  {
    name: "index-independents",
    path: "independents",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/independents/index.vue")
  },
  {
    name: "index",
    path: "",
    meta: indexDjBfQXnN9UMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/index.vue")
  },
  {
    name: "index-medical-centers",
    path: "medical-centers",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/medical-centers/index.vue")
  },
  {
    name: "index-medical-checkup-requests-id",
    path: "medical-checkup-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/medical-checkup-requests/[id].vue")
  },
  {
    name: "index-medical-checkup-requests",
    path: "medical-checkup-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/medical-checkup-requests/index.vue")
  },
  {
    name: "index-missions-id",
    path: "missions/:id()",
    meta: _91id_93IxB8DgD3gfMeta || {},
    redirect: _91id_93IxB8DgD3gfMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id].vue"),
    children: [
  {
    name: "index-missions-id-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id]/applications.vue")
  },
  {
    name: "index-missions-id-details",
    path: "details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id]/details.vue")
  },
  {
    name: "index-missions-id-find-talents",
    path: "find-talents",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id]/find-talents.vue")
  },
  {
    name: "index-missions-id-job-posting",
    path: "job-posting",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id]/job-posting.vue")
  },
  {
    name: "index-missions-id-notes",
    path: "notes",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/[id]/notes.vue")
  }
]
  },
  {
    name: "index-missions",
    path: "missions",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/missions/index.vue")
  },
  {
    name: "index-organigram",
    path: "organigram",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/organigram/index.vue")
  },
  {
    name: "index-pay-tracking-id",
    path: "pay-tracking/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/pay-tracking/[id].vue")
  },
  {
    name: "index-pay-tracking",
    path: "pay-tracking",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/pay-tracking/index.vue")
  },
  {
    name: "index-planning-contract_key",
    path: "planning/:contract_key()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/planning/[contract_key].vue")
  },
  {
    name: "index-planning",
    path: "planning",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/planning/index.vue")
  },
  {
    name: "index-ppe-requests-id",
    path: "ppe-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/ppe-requests/[id].vue")
  },
  {
    name: "index-ppe-requests",
    path: "ppe-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/ppe-requests/index.vue")
  },
  {
    name: "index-professional-card-requests-id",
    path: "professional-card-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/professional-card-requests/[id].vue")
  },
  {
    name: "index-professional-card-requests",
    path: "professional-card-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/professional-card-requests/index.vue")
  },
  {
    name: "index-profile",
    path: "profile",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/profile/index.vue")
  },
  {
    name: "index-qualification-centers-id",
    path: "qualification-centers/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/qualification-centers/[id].vue")
  },
  {
    name: "index-qualification-centers",
    path: "qualification-centers",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/qualification-centers/index.vue")
  },
  {
    name: "index-qualification-training-requests-id",
    path: "qualification-training-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/qualification-training-requests/[id].vue")
  },
  {
    name: "index-qualification-training-requests",
    path: "qualification-training-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/qualification-training-requests/index.vue")
  },
  {
    name: "index-recruitment-office-invoice-requests-id",
    path: "recruitment-office-invoice-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/recruitment-office-invoice-requests/[id].vue")
  },
  {
    name: "index-recruitment-office-invoice-requests",
    path: "recruitment-office-invoice-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/recruitment-office-invoice-requests/index.vue")
  },
  {
    name: "index-reference-checks",
    path: "reference-checks",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/reference-checks/index.vue")
  },
  {
    name: "index-settings",
    path: "settings",
    meta: index5b7i6UHe7aMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index.vue"),
    children: [
  {
    name: "index-settings-index-account",
    path: "account",
    meta: account968SQs7fJ6Meta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/account.vue")
  },
  {
    name: "index-settings-index-members",
    path: "members",
    meta: membersWgoyzjArYfMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/members.vue")
  },
  {
    name: "index-settings-index-workspace",
    path: "workspace",
    meta: workspacer5dASG9OJvMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/settings/index/workspace.vue")
  }
]
  },
  {
    name: "index-talents",
    path: "talents",
    meta: indexcf5KLjba2aMeta || {},
    redirect: indexcf5KLjba2aMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index.vue"),
    children: [
  {
    name: "index-talents-index-id",
    path: ":id()",
    meta: _91id_93oVcKrKMhKpMeta || {},
    redirect: _91id_93oVcKrKMhKpMeta?.redirect,
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id].vue"),
    children: [
  {
    name: "index-talents-index-id-anonymous-bio",
    path: "anonymous-bio",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/anonymous-bio.vue")
  },
  {
    name: "index-talents-index-id-anonymous-resume",
    path: "anonymous-resume",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/anonymous-resume.vue")
  },
  {
    name: "index-talents-index-id-applications",
    path: "applications",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/applications.vue")
  },
  {
    name: "index-talents-index-id-contracts",
    path: "contracts",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/contracts.vue")
  },
  {
    name: "index-talents-index-id-details",
    path: "details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/details.vue")
  },
  {
    name: "index-talents-index-id-documents",
    path: "documents",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/documents.vue")
  },
  {
    name: "index-talents-index-id-find-missions",
    path: "find-missions",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/find-missions.vue")
  },
  {
    name: "index-talents-index-id-new-page",
    path: "new-page",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/new-page.vue")
  },
  {
    name: "index-talents-index-id-notes",
    path: "notes",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/notes.vue")
  },
  {
    name: "index-talents-index-id-qualification",
    path: "qualification",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/qualification.vue")
  },
  {
    name: "index-talents-index-id-resume",
    path: "resume",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/[id]/resume.vue")
  }
]
  },
  {
    name: "index-talents-index-hiring-pools-id-details",
    path: "hiring-pools/:id()/details",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/hiring-pools/[id]/details.vue")
  },
  {
    name: "index-talents-index-hiring-pools",
    path: "hiring-pools",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/hiring-pools/index.vue")
  },
  {
    name: "index-talents-index-list",
    path: "list",
    meta: listKZdseRUrlFMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/list.vue")
  },
  {
    name: "index-talents-index-new-search",
    path: "new-search",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/new-search.vue")
  },
  {
    name: "index-talents-index-push-marketing",
    path: "push-marketing",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/push-marketing.vue")
  },
  {
    name: "index-talents-index-search",
    path: "search",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/search.vue")
  },
  {
    name: "index-talents-index-substitute-bench",
    path: "substitute-bench",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/talents/index/substitute-bench.vue")
  }
]
  },
  {
    name: "index-timesheets",
    path: "timesheets",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/timesheets/index.vue")
  },
  {
    name: "index-users",
    path: "users",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/users/index.vue")
  },
  {
    name: "index-work-accident-requests-id",
    path: "work-accident-requests/:id()",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/work-accident-requests/[id].vue")
  },
  {
    name: "index-work-accident-requests",
    path: "work-accident-requests",
    component: () => import("/opt/build/repo/apps/back-office/pages/index/work-accident-requests/index.vue")
  }
]
  },
  {
    name: "login",
    path: "/login",
    meta: loginEnHr1pJDIuMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout7j2RmEAQVaMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/logout.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingKgMjeqy0oIMeta || {},
    component: () => import("/opt/build/repo/apps/back-office/pages/onboarding.vue")
  },
  {
    name: "playground-accordions",
    path: "/playground/accordions",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/accordions.vue")
  },
  {
    name: "playground-button-copy",
    path: "/playground/button-copy",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/button-copy.vue")
  },
  {
    name: "playground-combobox",
    path: "/playground/combobox",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/combobox.vue")
  },
  {
    name: "playground-contract-status-select",
    path: "/playground/contract-status-select",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/contract-status-select.vue")
  },
  {
    name: "playground-error",
    path: "/playground/error",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/error.vue")
  },
  {
    name: "playground-facto",
    path: "/playground/facto",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/facto.vue")
  },
  {
    name: "playground-google-maps",
    path: "/playground/google-maps",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/google-maps.vue")
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/index.vue")
  },
  {
    name: "playground-langchain-streaming-with-structured-output",
    path: "/playground/langchain-streaming-with-structured-output",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/langchain-streaming-with-structured-output.vue")
  },
  {
    name: "playground-langchain-streaming",
    path: "/playground/langchain-streaming",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/langchain-streaming.vue")
  },
  {
    name: "playground-merge-to-pdf",
    path: "/playground/merge-to-pdf",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/merge-to-pdf.vue")
  },
  {
    name: "playground-messenger-email-dialog",
    path: "/playground/messenger-email-dialog",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/messenger-email-dialog.vue")
  },
  {
    name: "playground-monthFilterButton",
    path: "/playground/monthFilterButton",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/monthFilterButton.vue")
  },
  {
    name: "playground-note",
    path: "/playground/note",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/note.vue")
  },
  {
    name: "playground-pappers",
    path: "/playground/pappers",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/pappers.vue")
  },
  {
    name: "playground-protect-route",
    path: "/playground/protect-route",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/protect-route.vue")
  },
  {
    name: "playground-reporting-bar",
    path: "/playground/reporting-bar",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/reporting-bar.vue")
  },
  {
    name: "playground-sms-reminder",
    path: "/playground/sms-reminder",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/sms-reminder.vue")
  },
  {
    name: "playground-sms",
    path: "/playground/sms",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/sms.vue")
  },
  {
    name: "playground-supabase-dropzone",
    path: "/playground/supabase-dropzone",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/supabase-dropzone.vue")
  },
  {
    name: "playground-super-combobox",
    path: "/playground/super-combobox",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/super-combobox.vue")
  },
  {
    name: "playground-switch",
    path: "/playground/switch",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/switch.vue")
  },
  {
    name: "playground-talent-card",
    path: "/playground/talent-card",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/talent-card.vue")
  },
  {
    name: "playground-talent-request-preview",
    path: "/playground/talent-request-preview",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/talent-request-preview.vue")
  },
  {
    name: "playground-team-member-select-modal",
    path: "/playground/team-member-select-modal",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/team-member-select-modal.vue")
  },
  {
    name: "playground-timesheet-days",
    path: "/playground/timesheet-days",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-days.vue")
  },
  {
    name: "playground-timesheet-status-pill",
    path: "/playground/timesheet-status-pill",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-status-pill.vue")
  },
  {
    name: "playground-timesheet-time-format",
    path: "/playground/timesheet-time-format",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-time-format.vue")
  },
  {
    name: "playground-timesheet-time-input",
    path: "/playground/timesheet-time-input",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/timesheet-time-input.vue")
  },
  {
    name: "playground-vue-pdf",
    path: "/playground/vue-pdf",
    component: () => import("/opt/build/repo/apps/back-office/pages/playground/vue-pdf.vue")
  }
]